import React from 'react';
import {
  Admin,
  Resource,
  Create,
  SimpleForm,
  TextInput,
  DateInput, Edit,
  required,
  Datagrid, List, TextField, DateField
} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';


const dataProvider = simpleRestProvider('https://commonly.games/api');


export const AdjectiveList = (props) => (
  <List {...props} >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="adjective" />
      <DateField source="publish_on" />
    </Datagrid>
  </List>
);

const AdjectiveCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="adjective" validate={[required()]}/>
      <DateInput source="publish_on" validate={[required()]}/>
    </SimpleForm>
  </Create>
);

const AdjectiveEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="adjective" validate={[required()]}/>
      <DateInput source="publish_on" validate={[required()]}/>
      <TextInput source="top10" fullWidth multiline={true}/>
      <TextInput source="top25" fullWidth multiline={true}/>
      <TextInput source="top50" fullWidth multiline={true}/>
      <TextInput source="treasures" fullWidth multiline={true}/>
    </SimpleForm>
  </Edit>
);

const AdminPanel = () => (
  <Admin dataProvider={dataProvider}>
    <Resource name="adjective" list={AdjectiveList} create={AdjectiveCreate} edit={AdjectiveEdit}/>
  </Admin>
);

export default AdminPanel;
