import React, { useState, useEffect } from 'react';
import calendar from './../images/calendar.svg';
import dices from './../images/dices.svg';
import greyFlower from './../images/grey-flower.svg';
import greenFlower from './../images/green-flower.svg';
import yellowFlower from './../images/yellow-flower.svg';
import orangeFlower from './../images/orange-flower.svg';
import InputItem from './InputItem';
import dataScores from './scores.json';


const CommonlyGame = () => {
  const [adjectiveData, setAdjectiveData] = useState(null);
  const [scores, setScores] = useState(null);
  const [adjective, setAdjective] = useState(null);
  const [totalScore, setTotalScore] = useState(0);
  const [emojiResults, setEmojiResults] = useState([]);
  const [inputFields, setInputFields] = useState(Array(5).fill(''));
  const inputPlaceholders = ['Please write down', 'top 5 guesses for nouns', 'that are commonly used', 'alongside a given adjective', 'and try your luck.'];
  const [showResults, setShowResults] = useState(false);
  const [flowerResults, setFlowerResults] = useState(Array(5).fill(''));
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseAdjectiveData = await fetch('https://commonly.games/api/adjective/today');
        if (!responseAdjectiveData.ok) {
          throw new Error('HTTP error ' + responseAdjectiveData.status);
        }
        const dataAdjectiveData = await responseAdjectiveData.json();
        setAdjectiveData(dataAdjectiveData);
        setScores(dataScores);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (adjectiveData && scores) {
      initGame();
    }
  }, [adjectiveData, scores]);

  const initGame = () => {
    setAdjective(adjectiveData.adjective);
  };

  const handleInputChange = (index, event) => {
    setInputFields((prevInputFields) => {
      const newInputFields = [...prevInputFields];
      newInputFields[index] = event.target.value;
      return newInputFields;
    });
  };

  const handleSubmit = () => {
    if (validateFields()) {
      setAttempts(prevAttempts => prevAttempts + 1);
      submitGuesses();
    }
  };

  const validateFields = () => {
    return inputFields.every((input) => input.trim() !== '');
  };

  const submitGuesses = () => {
    if (!adjectiveData || !adjective) return;

    const combinedLists = [
      ...adjectiveData.top10,
      ...adjectiveData.top25,
      ...adjectiveData.top50,
    ];

    let newTotalScore = 0;
    let newEmojiResults = [];
    let newFlowerResults = [];

    const newResults = inputFields.map((guess) => {
      const rank = combinedLists.map((word) => word.toLowerCase()).indexOf(guess.trim().toLowerCase()) + 1;
      let flower = greyFlower;
      let emoji = '⬜';

      if (rank > 0 && rank <= 10) {
        newTotalScore += scores[rank];
        emoji = '🟩';
        flower = greenFlower;
      } else if (rank > 10 && rank <= 20) {
        newTotalScore += scores[rank];
        emoji = '🟨';
        flower = yellowFlower;
      } else if (rank > 20 && rank <= 50) {
        newTotalScore += scores[rank];
        emoji = '🟧';
        flower = orangeFlower;
      }

      return { emoji, flower };
    });

    newResults.forEach(result => {
      newEmojiResults.push(result.emoji);
      newFlowerResults.push(result.flower);
    });

    setTotalScore(newTotalScore);
    setEmojiResults(newEmojiResults);
    setFlowerResults(newFlowerResults);
    setShowResults(true);
  };

  const handleShare = () => {
    const message = `💡 Fun! Here are your results:\nWord of the day is "${adjective}"\nYour score is ${totalScore} points.\nRun was like this ${emojiResults.join('')}\n\nPlay with us at https://commonly.games`;
    copyToClipboard(message);
  };

  const copyToClipboard = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };

  useEffect(() => {
    if (attempts >= 5) {
      document.getElementById('exceeded-dialog').showModal();
    }
  }, [attempts]);

  if (!adjective) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container">
      <div className="firstline">
        <div className="icon-left">
          <img src={calendar} alt="Calendar" />
        </div>
        <p className="firstline">Adjective of the day is:</p>
        <div className="icon-right">
          <img src={dices} alt="Dices" />
        </div>
      </div>
      <h1 id="adjective">{adjective}</h1>
      <hr />
      {inputPlaceholders.map((placeholder, index) => (
        <InputItem
          key={index}
          index={index}
          placeholder={placeholder}
          handleInputChange={(event) => handleInputChange(index, event)}
          showResults={showResults}
          flower={flowerResults[index]}
        />
      ))}
      <button type="submit" className="submit-button" onClick={handleSubmit}>
        Submit your guesses
      </button>
      <button type="button" className="share-button" onClick={handleShare}>
        Your score is {totalScore}. Share it!
      </button>
      <dialog id="exceeded-dialog" className="dialog">
        <p>You have exceeded your limit of 5 tries.</p>
        <button onClick={() => document.getElementById('exceeded-dialog').close()}>Close</button>
      </dialog>
      {attempts >= 5 && Array(50).fill().map((_, index) => (
        <div
          key={index}
          style={{
            top: `${Math.random() * 100}%`,
            left: `${Math.random() * 100}%`,
          }}
          className="crazyBox"
        >
          🤡
        </div>
      ))}
    </div>
  );
};

export default CommonlyGame;
