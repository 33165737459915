import React from 'react';
import PropTypes from 'prop-types';

const ResultItem = ({ flower }) => (
  <div className="input-result">
    <p className="result"></p>
    <div className="icon-flower">
      <img src={flower} alt="flower" />
    </div>
  </div>
);

const InputItem = ({ index, placeholder, handleInputChange, showResults, flower }) => (
  <div className="inner-container">
    <div className="input-field">
      <p>{index + 1}.</p>
      <input type="text" placeholder={placeholder} onChange={handleInputChange} />
    </div>
    {showResults && <ResultItem flower={flower} />}
  </div>
);

InputItem.propTypes = {
  index: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  showResults: PropTypes.bool.isRequired,
  flower: PropTypes.string.isRequired
};

ResultItem.propTypes = {
  flower: PropTypes.string.isRequired
};

export default InputItem;
